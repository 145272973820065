import { renderAsync } from "docx-preview";
import React from "react";
import { DocumentProps, PageProps, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { isFunction } from "../../utils/isType";
import { ErrorDetectorModalView } from "../ErrorDecorator/ErrorDecorator";
import Modal, { IModal } from "../Modal/Modal";
import FileThumb, { IFileThumb } from "../PdfViewer/FileThumb";
import { PreviewAndDownload } from "../Upload/PreviewAndDownloadModal";
import { FileType } from "../Upload/Upload";
import styles from "./WordView.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

// declare const docx: any;

interface IWordViewer extends IModal {
  pdfDocument?: Omit<DocumentProps, "file">;
  pdfPage?: PageProps;
  file: URL | File | Blob;
  fileType?: FileType;
  fileThumb?: IFileThumb;
  actionType?: PreviewAndDownload;
  previewIndex?: number;
  activeIndex?: number;
  onThumbClick?: (enablePreview?: boolean) => void;
  onError?: (error: any) => void;
}

const WordViewer = ErrorDetectorModalView((props: IWordViewer) => {
  const {
    pdfDocument,
    pdfPage,
    file,
    fileThumb,
    fileType,
    actionType,
    previewIndex,
    activeIndex,
    onClose,
    onThumbClick,
    onError,
    ...rest
  } = props;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(
    () =>
      setVisible(
        PreviewAndDownload.PREVIEW === actionType &&
          previewIndex === activeIndex
      ),
    [actionType, previewIndex, activeIndex]
  );

  React.useEffect(() => {
    visible && thumbClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const clickHandler = React.useCallback(() => {
    isFunction(onThumbClick) && onThumbClick();
  }, [onThumbClick]);

  const closeHandler = React.useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      setVisible(false);
      isFunction(onClose) && onClose(e);
    },
    [onClose]
  );

  const onLoadWord = React.useCallback(
    (content: File | Blob | URL) => {
      const container = document.getElementById("wordContainer");
      if (!container) return;

      renderAsync(content, container, undefined, {
        ignoreWidth: true,
        inWrapper: false,
        className: styles.containerWord,
      }).catch((err) => {
        setVisible(false);
        isFunction(onError) && onError(err);
        isFunction(onClose) &&
          onClose({} as React.SyntheticEvent<Element, Event>);
      });
    },
    [onClose, onError]
  );

  const thumbClick = React.useCallback(() => {
    if (typeof file === "string") {
      fetch(file)
        .then((response) => response.blob())
        .then((res) => {
          const blob = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          onLoadWord(blob);
        })
        .catch((ex) => {
          isFunction(onError) && onError(ex);
          console.log(`Error: ${ex.message}`);
          setVisible(false);
          isFunction(onClose) &&
            onClose({} as React.SyntheticEvent<Element, Event>);
        });
    } else onLoadWord(file);
  }, [file, onClose, onError, onLoadWord]);

  return (
    <>
      <FileThumb {...fileThumb} onClick={clickHandler} />
      <Modal
        {...rest}
        visible={visible}
        onClose={closeHandler}
        className={styles.modelContainer}
        destroyOnClose={true}
      >
        <div id="wordContainer" onClick={closeHandler} />
      </Modal>
    </>
  );
});

export default WordViewer;
